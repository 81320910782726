import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import MadnessGenerator from '@/components/MadnessGenerator'

export default () => (
  <Layout>
    <Seo
      title='Генератор эффектов безумия для D&D'
    />
    <MadnessGenerator/>
  </Layout>
)
