import React from 'react'

import PageTitle from '@/components/PageTitle'

import MadnessShortGenerator from './components/MadnessShortGenerator'
import MadnessLongGenerator from './components/MadnessLongGenerator'
import MadnessContiniousGenerator from './components/MadnessContiniousGenerator'

import './MadnessGenerator.less'

export default () => (
  <section className='MadnessGenerator'>
    <PageTitle>Генератор эффектов безумия для D&D</PageTitle>
    <ul className='MadnessGenerator_list'>
      <li className='MadnessGenerator_item'>
        <MadnessShortGenerator/>
      </li>
      <li className='MadnessGenerator_item'>
        <MadnessLongGenerator/>
      </li>
      <li className='MadnessGenerator_item'>
        <MadnessContiniousGenerator/>
      </li>
    </ul>
  </section>
)
