import React from 'react'

import SimpleTableGenerator from '@/components/SimpleTableGenerator'

import list from './constants/list'

export default () => (
  <SimpleTableGenerator
    title='Кратковременное безумие'
    list={list}
  />
)
